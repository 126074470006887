<template>
  <div style="padding: 15px;">
    <div style="text-align:center;font-size: 20px;font-weight: bold;">
      {{title}}
    </div>
    <div
      v-html="content"
      style="
    text-align: left;"
    ></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
      title:'',
    };
  },
  mounted() {
    this.axios.get("http://112.250.105.14:10087/index.php/gu/get_gu_article_by_id?id=7").then((res) => {
      if (res.data.code == 0) {
        this.content = this.htmlDecode(res.data.result.content);
        this.title = res.data.result.title;
      }
    });
  },
  methods: {
    htmlDecode: function(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      var temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
  },
};
</script>
